
<template>
      <v-navigation-drawer
        v-model="drawer"
        :rail="rail"
        permanent
        expand-on-hover
      >
        <v-list-item
          :prepend-avatar = project.logo
          :title= project.title
          nav
        >
          <template v-slot:append>
          </template>
        </v-list-item>
        <v-divider></v-divider>
        <v-list density="compact" nav>
          <v-list-item 
          v-for="l in DashboardList"
          :key="l"
          :prepend-icon= l.icon 
          :title= l.title
          :value= l.value 
          @click="rout(l.route)">
          </v-list-item>
          <v-list-item
          class="cursor"
          prepend-icon= "mdi-exit-run"
          title= "Выход"
          @click="exit()"
          >
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
</template>

<style>

</style>
<script>
  export default {
    data () {
      return {
        drawer: true,
        rail: true,
        project: 
            {logo: process.env.VUE_APP_API_LOGO,
            title: process.env.VUE_APP_API_TITLE
            },
        DashboardList: [
        {
            title: "Главный экран",
            icon: "mdi-home-city",
            value: "home",
            route: '/' 
        },
        {
            title: "Пользователи",
            icon: "mdi-account-group",
            value: "users",
            route: '/about'  
        },
        {
            title: "Заявки  на  услуги",
            icon: "mdi-bullhorn-outline",
            value: "services",
            route: '/Services' 
        },
        {
            title: "Список организаторов",
            icon: "mdi-file-tree",
            value: "org",
            route: '/Org'
        },
        {
            title: "Список заведений",
            icon: "mdi-coffee",
            value: "shop",
            route: '/Shop' 
        },
        {
            title: "Список мероприятий",
            icon: "mdi-calendar-edit",
            value: "event",
            route: '/Event' 
        },
        {
            title: "Управление закрепами",
            icon: "mdi-pin",
            value: "control",
            route: '/Control' 
        },
        {
            title: "Управление Баннерами",
            icon: "mdi-card-text-outline",
            value: "Banners",
            route: '/Banners' 
        },
        {
            title: "Управление тарифами",
            icon: "mdi-credit-card-outline",
            value: "money",
            route: '/money' 
        },
        {
            title: "Отчеты",
            icon: "mdi-clipboard-edit-outline",
            value: "Report",
            route: '/Report' 
        },
        {
            title: "Управление справочниками",
            icon: "mdi-playlist-edit",
            value: "list",
            route: '/List' 
        },
        {
            title: "Настройки",
            icon: "mdi-cog",
            value: "settings",
            route: '/Settings' 
        },
        // {
        //     title: "Отчеты и модерация отчетов",
        //     icon: "mdi-message-reply",
        //     value: "reports",
        //     route: '/Reports' 
        // },
        {
            title: "Журнал транзакций",
            icon: "mdi-currency-usd",
            value: "cost",
            route: '/Cost' 
        },
        {
            title: "Пуш уведомления",
            icon: "mdi-bell-outline",
            value: "push",
            route: '/Push' 
        },
        // {
        //     title: "Статистика",
        //     icon: "mdi-chart-line",
        //     value: "stats",
        //     route: '/' 
        // },
        ]
      }
    },
    methods: {
      exit() {
        localStorage.setItem('access_token','')
        this.$store.state.login = false;
        this.$store.state.token = ''
      },
      rout(r) {
            this.$router.push(r);
      },
    }
  }
</script>