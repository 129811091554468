<script setup>
import SideBar from './components/SideBar.vue';

import { useTheme } from 'vuetify'

useTheme().global.name.value = 'dark';
// function toggleTheme () {
//   theme.global.name.value = theme.global.current.value.dark ? 'light' : 'dark'
// },
// function toggleTheme() {
//   if (theme.global.current.value.dark) {
//     theme.global.name.value = 'light';
//   } else {
//     theme.global.name.value = 'dark';
//   }
// }
</script>
<template >
  <!-- <v-app v-if="$store.state.login == true" theme="dark"> -->
      <v-app v-if="$store.state.login == true">
    <SideBar/>
    <v-main>
      <!-- <div>{{$store.state.token}}</div> -->
      <router-view/>
    </v-main>
  </v-app>
  <!-- <v-app v-if="$store.state.login == false" theme="dark"> -->
    <v-app v-if="$store.state.login == false">
    <v-main>
      <v-container>
        <div style="width: 50%; margin: auto; margin-top: 30vh;">
          <v-card
          class="mx-auto"
          >
              <template v-slot:title>
                <p style="text-align: center;">Админка {{title}}</p>
              </template>

              <v-card-text>
          <v-text-field
            label="Login"
            required
            v-model="Admin.username"
          >
          </v-text-field>

          <v-text-field
            label="Pass"
            required
            v-model="Admin.password"
          >
          </v-text-field>
          <div class="NoErr" v-show="Error == false"></div>
          <div class="Err" v-show="Error == true">Неверный логин или пароль</div>
          <v-checkbox
            v-model="MainCheckbox"
            :label="`Запомнить меня`"
          ></v-checkbox>
          <!-- <v-btn @click="$store.state.login = true"> -->
             <v-btn @click="AdminLog()" class="main-btn">
          Войти
          </v-btn>
              </v-card-text>
          </v-card>
        </div>

    </v-container>
    </v-main>
  </v-app>
</template>

<script>  
export default {
  name: 'App',
  data: () => ({
   title: process.env.VUE_APP_API_TITLE,
   MainCheckbox: false,
   Error: false,
   Admin: {
      username: '',
      password: ''
   },
   access_token_AppData: ''

  }),
  methods: {
      rout(r) {
            this.$router.push(r);
      },
AdminLog() {
  fetch( process.env.VUE_APP_API_ROOT + '/api/v1/auth/admin', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json'
    },
    body: JSON.stringify({
      "username": this.Admin.username,
      "password": this.Admin.password  
    }) 
  })
    .then(response => {
      if (response.status === 401) {
        console.log('Ошибка авторизации');
      } else if (response.status === 400) {
        this.Error = true;
      } else if (response.ok) {
        return response.json();
      } else {
        throw new Error('Ошибка при выполнении запроса');
      }
    })
    .then(data => {
      this.$store.state.token = data.data.access_token
      localStorage.setItem('access_token',data.data.access_token.replace(/"/g, ''))
      localStorage.setItem('refresh_token',data.data.refresh_token.replace(/"/g, ''))
      this.$store.state.login = true
        if(this.MainCheckbox == true) {
        this.$store.state.token = localStorage.getItem('access_token')
        } else {
          localStorage.setItem('access_token','')
        }
    })
    }    
  },
  async mounted() {
    this.$store.state.token = localStorage.getItem('access_token')
    await fetch(process.env.VUE_APP_API_ROOT + '/api/v1/events/all', {
        method: 'get',
        headers: {
          'Authorization': `Bearer ${this.$store.state.token}`
        },
    })
    .then(response => {
      if (response.status === 401) {
        this.$store.state.login = false;
      } else if (response.ok) {
        this.$store.state.login = true;
      } else {
        throw new Error('Ошибка при выполнении запроса');
      }
    })
  },
  // beforeMount () {
  //   if(localStorage.getItem('access_token') != '') {
  //     this.$store.state.login = true;
  //   } else {
  //     this.$store.state.login = false;
  //   }
  // }
 }
</script>
<style src="./style.css">
.login {
  margin-top: 500px;
  justify-content: center;
  text-align: center;
}

</style>