<script setup>
import BaseCard from '../components/BaseCard.vue';
import SladerRadius from '../components/SladerRadius.vue';
</script>
<template>
<v-container>
  <h1>Главный экран</h1>
<!-- <div>Активный токен админа:</div>
 <div>{{$store.state.token}}</div>
 <v-btn class="main-btn" @click="copyText()">Копировать токен</v-btn> -->
<v-row>
  <v-col
  class="ma-8"
  v-for="n in cartInfo"
  :key="n"
  cols="3">
    <!-- <BaseCard /> -->
    <div><BaseCard 
    :project_data="n"/>    
    </div>
  </v-col>
</v-row>
  <SladerRadius/>
  </v-container>
</template>

<script>
export default {
  data(){
    return{
      cartInfo: [ 
      {
        cartName: 'первая карточка',
        title: 'кол-во пользователей',
        content: 'N'
      },
      {
        cartName: 'вторая карточка',
        title: 'кол-во заведений',
        content: 'N'
      },
      {
        cartName: 'третья карточка',
        title: 'кол-во мероприятий',
        content: 'N'
      },
      {
        cartName: 'четвертая карточка',
        title: 'кол-во запросов отчетов',
        content: 'N'
      },
      ],
    }
  },
  methods: {
    copyText() {
      const el = document.createElement('textarea');
      el.value = this.$store.state.token;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    }
  },
  async mounted() {
      const events =  await fetch(process.env.VUE_APP_API_ROOT + '/api/v1/events/all', {
        method: 'get',
        headers: {
          'Authorization': `Bearer ${this.$store.state.token}`
        },
      })
      const dataevents = await events.json();
      this.cartInfo[2].content = await dataevents.pagination.totalItems;
      const shop =  await fetch(process.env.VUE_APP_API_ROOT + '/api/v1/places', {
      method: 'get',
      headers: {
        'Authorization': `Bearer ${this.$store.state.token}`
      },
    })
    const datashop = await shop.json();
    this.cartInfo[1].content = await datashop.pagination.totalItems;
    const users =await fetch(process.env.VUE_APP_API_ROOT + '/api/v1/users/?type=user', {
      method: 'get',
      headers: {
        'Authorization': `Bearer ${this.$store.state.token}`
      },
    })
    const datausers = await users.json();
    this.cartInfo[0].content = await datausers.pagination.totalItems;
    const rep =await fetch(process.env.VUE_APP_API_ROOT + '/api/v1/report-request', {
      method: 'get',
      headers: {
        'Authorization': `Bearer ${this.$store.state.token}`
      },
    })
    const datarep = await rep.json();
    this.cartInfo[3].content = await datarep.pagination.totalItems;
  }
}
</script>

<style>

</style>