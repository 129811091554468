import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/home2',
    name: 'hom',
    component: () => import('../views/HomePage.vue')
  },
  {
    path: '/Report',
    name: 'Report',
    component: () => import('../views/ReportsPage.vue')
  },
  {
    path: '/Org',
    name: 'Org',
    component: () => import('../views/OrgPage.vue')
  },
  {
    path: '/Shop',
    name: 'Shop',
    component: () => import('../views/ShopPage.vue')
  },
  {
    path: '/Event',
    name: 'Event',
    component: () => import('../views/EventPage.vue')
  },
  {
    path: '/Control',
    name: 'Control',
    component: () => import('../views/ControlPage.vue')
  },
  {
    path: '/Banners',
    name: 'Banners',
    component: () => import('../views/BannersPage.vue')
  },
  {
    path: '/money',
    name: 'Money',
    component: () => import('../views/MoneyPage.vue')
  },
  {
    path: '/push',
    name: 'Push',
    component: () => import('../views/PushPage.vue')
  },
  {
    path: '/List',
    name: 'List',
    component: () => import('../views/ListPage.vue')
  },
  {
    path: '/Reports',
    name: 'Reports',
    component: () => import('../views/ReportsPage.vue')
  },
  {
    path: '/Cost',
    name: 'Cost',
    component: () => import('../views/CostPage.vue')
  },
  {
    path: '/Push',
    name: 'Push',
    component: () => import('../views/PushPage.vue')
  },
  {
    path: '/Settings',
    name: 'Settings',
    component: () => import('../views/SettingsPage.vue')
  },
  {
    path: '/Services',
    name: 'Services',
    component: () => import('../views/ServicesPage.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
