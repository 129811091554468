<template>
  <v-card
    class="mx-auto card-head"
  >
    <v-toolbar
      flat
      dense
    >
      <v-toolbar-title>
        <span>Радиус поиска "друзья/интересное рядом"</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-card-text>
      <v-row
        class="mb-4"
        justify="space-between"
      >
        <v-col class="text-left">
          <span
            class="text-h2 font-weight-light"
            v-text="bpm"
          ></span>
          <span class="subheading font-weight-light me-1"> Метров</span>
          <v-fade-transition>
            <v-avatar
              v-if="isPlaying"
              :color="color"
              :style="{
                animationDuration: animationDuration
              }"
              class="mb-1 v-avatar--metronome"
              size="12"
            ></v-avatar>
          </v-fade-transition>
        </v-col>
        <v-col class="text-right">
          <v-btn
          class="main-btn"
            theme="dark"
            @click="RadiusSubmit()"
          >
            Подтвердить
          </v-btn>
        </v-col>
      </v-row>

      <v-slider
        v-model="bpm"
        :color="color"
        track-color="grey"
        min="0"
        max="10000"
        :step="100"
      >
        <template v-slot:prepend>
          <v-btn
            size="small"
            variant="text"
            icon="mdi-minus"
            :color="color"
            @click="decrement"
          ></v-btn>
        </template>

        <template v-slot:append>
          <v-btn
            size="small"
            variant="text"
            icon="mdi-plus"
            :color="color"
            @click="increment"
          ></v-btn>
        </template>
      </v-slider>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  data(){
    return{
      bpm: 1000,
      isPlaying: false,
      AppSettings:[]
    }
  },
    computed: {
      color () {
        if (this.bpm < 1000) return '#d88bb4'
        if (this.bpm < 4000) return 'var(--modal-color)'
        if (this.bpm < 8000) return 'var(--main-bg-good)'
        return '#9e175f'
      },
      animationDuration () {
        return `${0 / this.bpm}s`
      },
    },

    methods: {
      decrement () {
        this.bpm--
      },
      increment () {
        this.bpm++
      },
      toggle () {
        this.isPlaying = !this.isPlaying
      },
    RadiusSubmit() {
      fetch(process.env.VUE_APP_API_ROOT + '/api/v1/app-settings/' + this.AppSettings.id + '?name=Радиус поиска (друзья/мероприятия/заведения)&code=max_search_radius&value=' + this.bpm, {
      method: 'PATCH',
      headers: {
        'Authorization': `Bearer ${this.$store.state.token}`
      },      
    })
    .then(response => {
      if (response.status === 200) {
        this.modal = false
        setTimeout(async() =>{
          this.Rebut()
        }, 1000)
      }
    })
    },
    async Rebut(){
      const radius =  await fetch(process.env.VUE_APP_API_ROOT + '/api/v1/app-settings/by-code/' + 'max_search_radius', {
        method: 'get',
        headers: {
          'Authorization': `Bearer ${this.$store.state.token}`,
          'Content-Type': 'application/json'
        },
      })

      const dataradius = await radius.json();
      this.AppSettings = dataradius.data
      this.bpm = dataradius.data.value
    }
    },
    async mounted() {
      const radius =  await fetch(process.env.VUE_APP_API_ROOT + '/api/v1/app-settings/by-code/' + 'max_search_radius', {
        method: 'get',
        headers: {
          'Authorization': `Bearer ${this.$store.state.token}`
        },
      })
      const dataradius = await radius.json();
      this.AppSettings = dataradius.data
      this.bpm = dataradius.data.value

    }
}
</script>
